<template>
  <div id="result-class-booking-component">
    <BRow
      v-if="!isEmpty(getSearchFlightArray)"
      no-gutters
    >
      <BCol
        cols="12"
        lg="6"
        class="px-50"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="tab-title"
          align="center"
          nav-wrapper-class="nav-wrapper-class"
        >
          <b-tab
            v-for="(itinerary, itineraryIndex) in getSearchFlightArray"
            :key="itineraryIndex"
          >
            <template #title>
              <div class="d-flex-column">
                <div
                  :class="`d-flex text_info_class_booking fw-700 mb-50 text-nowrap
                ${ ['xs', 'sm', 'MD'].includes(appBreakPoint) ? 'font-medium-1' : 'font-medium-3' }`"
                >
                  #{{ itineraryIndex + 1 }} {{ itinerary.startPoint }}-{{ itinerary.endPoint }} {{ convertISODateTime(itinerary.departDate).dayAndMonth }}
                </div>
                <div
                  v-if="!selectedTrips[itineraryIndex] || !selectedTrips[itineraryIndex][0]"
                  class="d-flex-center"
                >
                  <span class="text-warning font-weight-bolder font-small-4">
                    Chưa chọn chuyến
                  </span>
                </div>
                <div
                  v-else-if="
                    selectedTrips[itineraryIndex] &&
                      selectedTrips[itineraryIndex][0]
                  "
                  class="d-flex-center"
                >
                  <div class="text-nowrap font-weight-bolder">
                    {{ selectedTrips[itineraryIndex][0].airline
                    }}{{ selectedTrips[itineraryIndex][0].flightNumber }}
                    {{ selectedTrips[itineraryIndex][0].bookingClass.code }}
                    {{
                      convertISODateTime(
                        selectedTrips[itineraryIndex][0].departureDate,
                        selectedTrips[itineraryIndex][0].departureTimezone,
                      ).hourMin
                    }}
                    {{
                      convertISODateTime(
                        selectedTrips[itineraryIndex][0].arrivalDate,
                        selectedTrips[itineraryIndex][0].arrivalTimezone,
                      ).hourMin
                    }}
                  </div>
                </div>
              </div>
            </template>

            <!-- ANCHOR - TRIPS -->
            <template v-if="flightsData && flightsData[itineraryIndex] && flightsData[itineraryIndex].trips && !isEmpty(flightsData[itineraryIndex].trips)">
              <!-- NOTE Từng hành trình search -->
              <vue-perfect-scrollbar
                :settings="{ maxScrollbarLength: 150 }"
                class="flight-details scroll-area"
              >
                <b-card
                  v-for="(trip, tripIndex) in flightsData[itineraryIndex].trips"
                  :key="tripIndex"
                  class="border shadow mb-50"
                  body-class="px-50 py-50"
                >
                  <b-row
                    v-for="(segment, segmentIndex) in trip.segments"
                    :key="segmentIndex"
                    no-gutters
                  >
                    <b-col
                      cols="12"
                      class="text-dark d-flex flex-wrap"
                      :class="segmentIndex > 0 ? 'pl-2' : 'pl-25'"
                    >
                      <div class="d-flex align-items-center fw-700 font-medium-1 mr-75">
                        <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).time }}-{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time }}</span>
                        <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth }}</span>
                        <span class="mr-50 text-nowrap">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                        <span class="mr-50 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
                        <span class="text-nowrap">{{ `| ${segment.airCraft}` }}</span>
                      </div>
                    </b-col>

                    <b-col
                      cols="12"
                      :class="segmentIndex > 0 ? 'pl-2' : ''"
                    >
                      <!-- style="min-width: 30px; min-height: 35px" -->
                      <b-button
                        v-for="(bookingClass, index) in segment.bookingClassAvail"
                        :id="`btn-booking-class-${bookingClass.uniqueSegmentId}`"
                        :key="index"
                        variant="transparent"
                        class="mr-50 mb-25 px-25 py-0"
                        :class="`btn_booking_class mr-25 mb-25 px-25 py-0
                          ${resolveClassColor(bookingClass)}
                        `"
                        style="min-width: 30px"
                        @click="handleSelectClassBooking(segment, bookingClass)"
                      >
                        <div class="fw-700">
                          {{ `${bookingClass.code}${bookingClass.availability}` }}
                        </div>

                        <b-tooltip
                          v-if="appBreakPoint !== 'xs'"
                          :id="`tooltip-booking-class-${bookingClass.uniqueSegmentId}`"
                          :target="`btn-booking-class-${bookingClass.uniqueSegmentId}`"
                          :variant="bookingClass.availability === 0 ? 'secondary' : 'info'"
                          triggers="hover"
                          custom-class="tooltip-index-2"
                          placement="top"
                          boundary="viewport"
                        >
                          <h6 class="text-white py-25 mb-0">
                            {{ bookingClass.fareBasisCode }}
                          </h6>
                          <i class="mb-0 font-medium-2">
                            {{ bookingClass.fare ? formatCurrency(bookingClass.fare) : 0 }}
                          </i>
                        </b-tooltip>
                      </b-button>
                      <i class="text-danger font-weight-bolder text-uppercase text-nowrap font-small-3">
                        {{ segment.operating ? getAirlineNameByCode(segment.operating) : '' }}
                      </i>
                    </b-col>
                  </b-row>
                </b-card>
              </vue-perfect-scrollbar>
            </template>

            <b-alert
              v-else-if="flightsData && flightsData[itineraryIndex] && flightsData[itineraryIndex].trips && isEmpty(flightsData[itineraryIndex].trips)"
              variant="danger"
              show
              class="text-center px-50 px-lg-0 py-75 py-lg-1 my-2 font-weight-bolder font-medium-2"
            >
              <feather-icon
                icon="InfoIcon"
                size="21"
                class="text-danger mr-25"
                style="margin-bottom: 3px"
              />
              Không có chuyến bay được khai thác trên hành trình này
            </b-alert>

            <div
              v-else
              class="text-center mt-3"
              style="min-height: 100px"
            >
              <b-spinner variant="info" />
              <p class="text-info">
                Đang tìm kiếm chuyến bay...
              </p>
            </div>
          </b-tab>
        </b-tabs>
      </BCol>

      <BCol
        cols="12"
        lg="6"
        class="px-50"
      >
        <CardAddFlightClassBooking
          :selected-trips="selectedTrips"
          :booking-data="bookingData"
          :search-flight-array="getSearchFlightArray"
          @updateTripInfo="updateDataTripAfterCalculatePrice"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BButton,
  BCard,
  BCol,
  BRow,
  BTooltip,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import {
  ref, onBeforeMount, onUnmounted,
} from '@vue/composition-api'
import { isEmpty, cloneDeep } from 'lodash-es'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { v4 as uuidv4 } from 'uuid'

import store from '@/store'

import {
  formatCurrency,
  convertISODateTime,
} from '@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    BTooltip,
    BSpinner,
    BAlert,

    VuePerfectScrollbar,

    CardAddFlightClassBooking: () => import('./CardAddFlightClassBooking.vue'),

  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['app-reservation/getTabIndex']
      },
      set(newValue) {
        this.$store.dispatch('app-reservation/setTabIndex', newValue)
      },
    },
  },
  setup() {
    const { toastError } = useToast()
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) { store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule) }
    onUnmounted(() => { if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) { store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME) } })

    const {
      searchClassBooking,
      getAirlineNameByCode,
      getSearchFlightArray,
    } = useReservationDetailBookingHandle()

    const query = localStorage.getItem('search-for-modify')
    const searchData = ref({ ...JSON.parse(query) })
    const getBookingData = localStorage.getItem('bookingData')
    const bookingData = ref({ ...JSON.parse(getBookingData) })
    const loading = ref(false)
    const flightsData = ref(null)
    const selectedTrips = ref([])
    const status = ref([])
    const listSelectedClassId = ref([])
    const segmentInfo = ref([])

    function getUniqueBookingClassIds(data) {
      return data
        .flat()
        .flatMap(item => item && item.bookingClass && item.bookingClass.uniqueBookingClassId
          ? [item.bookingClass.uniqueBookingClassId]
          : [])
        .filter(Boolean)
    }

    function handleSelectClassBooking(segment, bookingClass) {
      const dataTrip = selectedTrips.value[segment.itineraryIndex]
      const equalTripIndex = dataTrip?.find(item => item)?.tripIndex === segment.tripIndex || undefined
      const { bookingClassAvail, ...rest } = segment
      const tripDraft = cloneDeep(selectedTrips.value)
      const statusDraft = cloneDeep(status.value)

      const updateDrafts = reset => {
        if (reset) {
          tripDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => null)]
          statusDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => 'NN')]
        }
        tripDraft[segment.itineraryIndex][segment.leg] = { ...rest, bookingClass }
        statusDraft[segment.itineraryIndex][segment.leg] = bookingClass.status
        selectedTrips.value = tripDraft
        status.value = statusDraft
      }

      if (!isEmpty(dataTrip)) { // Có data
        if (equalTripIndex) { // Khi đã chọn segment tương ứng với index của segment trong cùng Trip và Itinerary
          updateDrafts(false)
        } else { // Có data và chọn sang hành trình khác trong cùng Itinerary
          updateDrafts(true)
        }
      } else { // No data
        updateDrafts(true)
      }

      // indexBookingClassShowPrice.value = bookingClass.uniqueBookingClassId
      listSelectedClassId.value = getUniqueBookingClassIds(selectedTrips.value)
    }

    function findBaseSegmentByUniqueSegmentId(uniqueSegmentId) {
      let result = null
      flightsData.value[0].trips.forEach(trip => {
        trip.segments.forEach(segment => {
          if (segment.uniqueSegmentId === uniqueSegmentId) {
            result = segment
          }
        })
      })
      return result
    }

    function updateDataTripAfterCalculatePrice(tripInfos = []) {
      const segmentErrorList = []
      // const bookingClassChanged = false // cần thì làm sau, so sánh bookingClass trong newSeg với bookingClass đã chọn
      tripInfos.forEach(newSeg => {
        const {
          uniqueSegmentId, bookingClass, departure, arrival, flightNumber, airline, departureDate, departureTimezone,
        } = newSeg
        const baseSegment = findBaseSegmentByUniqueSegmentId(uniqueSegmentId)
        const bookingClassObj = baseSegment ? baseSegment.bookingClassAvail.find(item => item.code === bookingClass) : null
        if (baseSegment || bookingClassObj) {
          handleSelectClassBooking(baseSegment, bookingClassObj)
        } else {
          segmentErrorList.push(`${airline}${flightNumber} ${departure}-${arrival} ${convertISODateTime(departureDate, departureTimezone).date}`)
        }
      })
      if (!isEmpty(segmentErrorList)) {
        toastError({
          title: 'messagesList.error',
          content: `Lỗi tìm kiếm ${segmentErrorList.length} chuyến bay ${segmentErrorList.join(', ')}`,
        })
      }
    }

    function addIndexSegmentToData(data) {
      selectedTrips.value = data.map(() => null)
      listSelectedClassId.value = data.map(() => [])
      const result = data.map((item, itineraryIndex) => {
        const updatedTrips = item.trips.map((trip, tripIndex) => {
          const updatedSegments = trip.segments.map((segment, segmentIndex) => ({
            ...segment,
            uniqueSegmentId: uuidv4(),
            legsTrip: trip.segments.length,
            leg: segmentIndex,
            tripIndex,
            itineraryIndex,
            bookingClassAvail: segment.bookingClassAvail.map(item => ({ ...item, uniqueBookingClassId: uuidv4() })),
          }))
          return { ...trip, segments: updatedSegments }
        })
        return { ...item, trips: updatedTrips }
      })
      return result
    }

    onBeforeMount(() => {
      loading.value = true
      searchClassBooking(searchData.value)
        .then(res => {
          flightsData.value = addIndexSegmentToData(res)
        })
        .catch(err => {
          toastError({
            title: 'messagesList.error',
            content: err.message,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })

    function resolveClassColor(bookingClass) { // VN1A
      if (listSelectedClassId.value.includes(bookingClass.uniqueBookingClassId)) {
        return `${bookingClass.status}_seats selected_${bookingClass.status}_seats`
      }
      return `${bookingClass.status}_seats`
    }

    return {
      loading,
      flightsData,
      selectedTrips,
      listSelectedClassId,
      resolveClassColor,
      formatCurrency,
      convertISODateTime,
      handleSelectClassBooking,

      searchData,

      bookingData,

      getSearchFlightArray,
      segmentInfo,
      isEmpty,
      getAirlineNameByCode,
      updateDataTripAfterCalculatePrice,
    }
  },
}
</script>

<style lang="scss" scoped>
#result-class-booking-component ::v-deep {
  .nav-wrapper-class {
    display: block;
    // display: none;
    position: sticky;
    top: 50px;
    z-index: 10;
    background: rgba(#e9f1fc, 0.8);
    backdrop-filter: blur(5px);
    // margin: 0 0 2rem 0;
    border-radius: 8px;
    padding: 0;

    .nav-pills {
      margin: 0;
    }
  }

  .nav {
    flex-wrap: nowrap;
    justify-content: start !important;
    overflow-x: auto;
  }

  .tab-title .nav-item {
    min-width: auto;
    margin: 0.6rem 0px;
    background: #ffffffc5;
    box-shadow: 0px 4px 6px rgba(0, 12, 43, 0.308);
    border-radius: 8px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: .8rem;
    display: flex;
    align-items: center;

    .nav-link {
      padding: 0.8rem 1rem 0.5rem;

      @media (max-width: 576px) {
        padding: 0.5rem 0.8rem 0.5rem;
      }
    }

    .nav-link.active {
      background: #9bd5eb;
      height: 100%;
      width: 100%;
      color: #166987;
      border: none;
    }
  }
}

::v-deep .btn_booking_class {
  min-width: 30px;
  min-height: 30px;

  .tooltip {
    z-index: 2 !important;
  }
}

.NN_seats {
  color: #166987;
  border: 2px solid #166987;

  &.selected_NN_seats {
    background-color: #166987;
    color: white;
  }
}

.LL_seats {
  color: #FFC107;
  border: 2px solid #FFC107;

  &.selected_LL_seats {
    background-color: #FFC107;
    border: 2px solid #FFC107;
    color: black;
  }
}

.DS_seats {
  color: #dc3545;
  border: 2px solid #dc3545;

  &.selected_DS_seats {
    background-color: #dc3545;
    border: 2px solid #dc3545;
    color: white;
  }
}

.text_info_class_booking {
  color: #166987;
}

.sticky_refresh {
  position: sticky;
  bottom: 0;
  margin: 0.5rem 0;
  border-radius: 8px;
  padding: 0.5rem 0 !important;

  @media (max-width: 576px) {
    bottom: 3px;
  }
}

.flight-details {
  height: 70vh;
}
</style>
